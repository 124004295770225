/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { TfiEmail } from 'react-icons/tfi';
import { IoIosCall } from 'react-icons/io';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
} from 'react-icons/fa';

const Footer = ({ id }) => {
  return (
    <footer id='footer' className='pt-40'>
      <div id='footer-bar-1' className='footer-bar'>
        <div className='footer-bar-wrap'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='fb-row sp'>
                  <div className='col-sm-4 footer-social'>
                    <div className='box-info box-info-2 text-white'>
                      <div className='box-content' id={id}>
                        <h5>اتصل بنا </h5>
                        <div className='footer_item'>
                          <div className='footer_item_Icon'>
                            <HiOutlineLocationMarker />
                          </div>
                          <div className='footer_item_text'>
                            ١٤٠٥ | Riyad | B.O.Box
                          </div>
                        </div>
                        <div className='footer_item'>
                          <div className='footer_item_Icon'>
                            <TfiEmail />
                          </div>
                          <div className='footer_item_text'>
                            <a href='mailto:info@lumaa-alibda.com'>
                              info@lumaa-alibda.com
                            </a>
                          </div>
                        </div>
                        <div className='footer_item'>
                          <div className='footer_item_Icon'>
                            <IoIosCall />
                          </div>
                          <div className='footer_item_text'>
                            <a href='tel:+966 59 963 9067'>9067 963 59 966 +</a>
                          </div>
                        </div>
                        <div className='footer_item_social'>
                          <a
                            target='_blank'
                            className='ln'
                            href={
                              'https://www.linkedin.com/company/lumaa-alibda/?viewAsMember=true'
                            }>
                            <FaLinkedinIn />
                          </a>
                          <a
                            target='_blank'
                            className='tw'
                            href={
                              'https://twitter.com/lumaa_alibda?s=21&t=qZxt_eeEQGrjHPJ22jjzwQ'
                            }>
                            <FaTwitter />
                          </a>
                          <a
                            target='_blank'
                            className='in'
                            href={
                              'https://www.instagram.com/lumaa_alibda/?igshid=MzRlODBiNWFlZA%3D%3D'
                            }>
                            <FaInstagram />
                          </a>

                          <a
                            target='_blank'
                            href={'https://wa.me/+966599639067'}
                            className='whats'>
                            <FaWhatsapp />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4'></div>
                  <div className='col-sm-4 div-footer-logo'>
                    <div className='box-info box-info-2 text-white mb-sm-50'>
                      <div className='box-content'>
                        <a className='logo logo-footer' href='#'>
                          <img src='/assets/images/logo.png' alt='' />
                        </a>
                        <p>
                          شركة سعودية نسعى فيها بكل جدٍ وإبداع على أن نكون جزء
                          من الحل لكثير من القطاعات المختلفة؛ وذلك بتقديم خدمات
                          احترافية متكاملة في جانب الخدمات المساندة.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='hide_footer'>
                  <div className='col-sm-4 footer-social'>
                    <div className='box-info box-info-2 text-white'>
                      <div className='box-content' id={id}>
                        <h5>اتصل بنا </h5>
                        <div className='footer_item'>
                          <div className='footer_item_Icon'>
                            <HiOutlineLocationMarker />
                          </div>
                          <div className='footer_item_text'>
                            ١٤٠٥ | Riyad | B.O.Box
                          </div>
                        </div>
                        <div className='footer_item'>
                          <div className='footer_item_Icon'>
                            <TfiEmail />
                          </div>
                          <div className='footer_item_text'>
                            <a href='mailto:info@lumaa-alibda.com'>
                              info@lumaa-alibda.com
                            </a>
                          </div>
                        </div>
                        <div className='footer_item'>
                          <div className='footer_item_Icon'>
                            <IoIosCall />
                          </div>
                          <div className='footer_item_text'>
                            <a href='tel:+966 59 963 9067'>9067 963 59 966 +</a>
                          </div>
                        </div>
                        <div className='footer_item_social'>
                          <a
                            className='ln'
                            href='https://www.linkedin.com/company/lumaa-alibda/?viewAsMember=true'
                            target='_blank'
                            rel='noreferrer'>
                            <FaLinkedinIn />
                          </a>
                          <a
                            target='_blank'
                            className='tw'
                            href='https://twitter.com/lumaa_alibda?s=21&t=qZxt_eeEQGrjHPJ22jjzwQ'
                            rel='noreferrer'>
                            <FaTwitter />
                          </a>
                          <a
                            target='_blank'
                            className='in'
                            href='https://www.instagram.com/lumaa_alibda/?igshid=MzRlODBiNWFlZA%3D%3D'
                            rel='noreferrer'>
                            <FaInstagram />
                          </a>
                          <a
                            target='_blank'
                            href='https://wa.me/+966599639067'
                            className='whats'
                            rel='noreferrer'>
                            <FaWhatsapp />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4'></div>
                  <div className='col-sm-4 div-footer-logo'>
                    <div className='box-info box-info-2 text-white mb-sm-50'>
                      <div className='box-content text-center'>
                        <a className='logo logo-footer' href='#'>
                          <img src='/assets/images/logo.png' alt='' />
                        </a>
                        <p>
                          شركة سعودية نسعى فيها بكل جدٍ وإبداع على أن نكون جزء
                          من الحل لكثير من القطاعات المختلفة؛ وذلك بتقديم خدمات
                          احترافية متكاملة في جانب العمالة والخدمات المساندة.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='footer-bar-2' className='footer-bar'>
        <div className='footer-bar-wrap'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='fb-row '>
                  <div className='copyrights-message'>
                    جميع الحقوق محفوظة &#169;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
