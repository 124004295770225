import React from 'react';
import { AiOutlineCar } from 'react-icons/ai';
import { GiVacuumCleaner } from 'react-icons/gi';
import { LiaFileContractSolid } from 'react-icons/lia';
import { GrHostMaintenance, GrTechnology } from 'react-icons/gr';
import { PiCallBellBold } from 'react-icons/pi';
import { TbTruckDelivery } from 'react-icons/tb';
import { MdStorage } from 'react-icons/md';
import Tabs from '../Tabs';
import Panel from '../Tabs/Panel';
import Container, { ContainerPadding } from '../Container';

const Services = ({ id }) => {
  return (
    <div className='pt-50'>
      <Container>
        <ContainerPadding width={'98%'}>
          <div id={id} className='col-md-12 pt-20 pb-20 services_border'>
            <div className='section-title  mb-0'>
              <h2>خدماتنا</h2>
            </div>
            <div className='slider-boxes-features'>
              <Tabs>
                <Panel title='خدمة توفير السائقين' icon={<AiOutlineCar />}>
                  <div className='tabs_content'>
                    يتميز فريق السائقين بمهارات عالية تخدم المجال اللوجستي، مثل
                    : <li> التحدث باللغتين (العربية، الإنجليزية)</li>
                    <li>التعامل مع تطبيقات التوصيل</li>
                    <li> القدرة على قيادة كافة انواع المركبات </li>
                  </div>
                </Panel>
                <Panel title='خدمات النظافة' icon={<GiVacuumCleaner />}>
                  <div className='tabs_content'>
                    نوفر خدمات النظافة الشاملة لجميع القطاعات المختلفة بأيدي
                    عاملة مدربة تحت ادارة مشرفين بخبرة واسعة، وتشمل الخدمات على:
                    <li>تنظيف وتعقيم الأرضيات ودورات المياه </li>
                    <li>
                      تنظيف المداخل والممرات ومواقف السيارات، أرضيات المكاتب
                      والاستقبال وتلميع جدران الواجهات الزجاجية
                    </li>
                    <li>
                      {' '}
                      إفراغ أكياس النفايات ووضعها في الاماكن المخصصة لها{' '}
                    </li>
                    <li> رش المبيد الحشري نهاية الدوام </li>
                  </div>
                </Panel>
                <Panel title='عقود العمالة' icon={<LiaFileContractSolid />}>
                  <div className='tabs_content'>
                    نوفر عمالة بمهارات عالية في مجال:
                    <li>النظافة </li>
                    <li> الضيافة</li>
                    <li>
                      التحميل والتنزيل لـ (المصانع المستودعات، ترتيب الأرفف)
                    </li>
                    <li>محطات الوقود وخدمات السيارات</li>
                    <li>المطاعم والمقاهي والمجمعات التجارية</li>
                    <li>خدمات المستشفيات والمراكز الطبية</li>
                  </div>
                </Panel>
                <Panel title='الصيانة والتشغيل' icon={<GrHostMaintenance />}>
                  <div className='tabs_content'>
                    نقوم بتقديم خدمات التشغيل والصيانة للمنشآت والمرافق المختلفة
                    مثل:
                    <li>المراكز والأسواق التجارية</li>
                    <li>مراكز الأعمال</li>
                    <li> المراكز الطبية</li>
                    <li>المجمعات والمرافق السكنية والصناعية</li>
                    من خلال البرامج والأنظمة التشغيلية المناسبة وتطبيق ما تقوم
                    إليه الحاجة من الأعمال، كما نقدم هذه الخدمات التصحيحية
                    والوقائية والخدمات الأخرى.
                  </div>
                </Panel>
                <Panel title='الحلول التقنية الذكية' icon={<GrTechnology />}>
                  <div className='tabs_content'>
                    نوفر الحلول التقنية لتطوير الخدمات اللوجستية وتأمين الأيدي
                    العاملة. ومن هذا المنطلق تم عقد شراكات بالتضامن مع شركة
                    أميال للحلول الرقمية؛ وذلك لتطوير خدماتنا لضمان أعلى معايير
                    الجودة ولضبط الأداء وسرعة الاستجابة
                  </div>
                </Panel>
                <Panel title='خدمات حسن الضيافة' icon={<PiCallBellBold />}>
                  <div className='tabs_content'>
                    نقدم خدمة الضيافة بأسلوب مميز ودقة متناهية وسرعة في التنفيذ.
                    ولأننا نسعى للتميز نهتم بالتفاصيل:
                    <li>
                      المظهر اللائق لمحضر الشاي، وكيفية حمله للأواني ولباقة
                      تقديمه للضيوف
                    </li>
                    <li>
                      تقديم الماء وتحضير المشروبات الساخنة بأنواعها كالقهوة
                      التركية والشاي الإنجليزي
                    </li>
                    <li>
                      إضافة حلويات ترحيبية في أماكن الاستقبال بأواني كريستال
                      فاخرة
                    </li>
                  </div>
                </Panel>
                <Panel title='التوصيل' icon={<TbTruckDelivery />}>
                  <div className='tabs_content'>
                    سائقون بمهارة عالية في قيادة كافة أنواع السيارات، وذو معرفة
                    باستخدام كافة أنواع الدعم اللوجستي، كما يتميزون بالقدرة على
                    التحدث باللغة العربية والانجليزية
                  </div>
                </Panel>
                <Panel title='التخزين' icon={<MdStorage />}>
                  <div className='tabs_content'>
                    عمالة متخصصة في التخزين وترتيب الأرفف بمهارة عالية
                  </div>
                </Panel>
              </Tabs>
            </div>
          </div>
        </ContainerPadding>
      </Container>
    </div>
  );
};

export default Services;
