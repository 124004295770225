import React from 'react';

const ScrollProgress = () => {
  return (
    <div id='scroll-progress'>
      <div className='scroll-progress'>
        <span className='scroll-percent'></span>
      </div>
    </div>
  );
};

export default ScrollProgress;
