/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import Container, { ContainerPadding } from '../Container';
import './styled.css';

const tempData = [
  {
    title: 'الرؤية',
    icon: 'vision',
    text: ' أن نكون الخيار الأمثل في مجال الخدمات المساندة بخبرات عالية.',
  },
  {
    title: 'الرسالة',
    icon: 'message',
    text: 'تقديم حلول إبداعية وخدمات متكاملة لكافة القطاعات بالتعاون والتعامل المرن والتجاوب السريع.',
  },
];
const Message = ({ id }) => {
  const [count, setCount] = useState(0);
  const [data] = useState(tempData);

  const increaseCount = () => {
    count == 1 ? setCount(0) : setCount(1);
  };
  const decreaseCount = () => {
    count == 0 ? setCount(1) : setCount(0);
  };

  return (
    <div id={id} className='col-md-12 pt-50 '>
      <div className='section-content'>
        <div className='row space_between row_message'>
          <Container>
            <ContainerPadding width={'100%'}>
              <div className='message'>
                <div
                  className={`message_card ${
                    data[count].icon == 'vision' ? 'gold' : 'blue'
                  }`}>
                  <div className='card_title'>{data[count].title}</div>
                  <div className='card_text'>{data[count].text}</div>
                </div>
                <div className='message_pagination'>
                  <button onClick={increaseCount}>{'<'}</button>
                  <button onClick={decreaseCount}>{'>'}</button>
                </div>
              </div>
            </ContainerPadding>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Message;
