import React from 'react';
import { styled } from 'styled-components';

const Container = ({ children, id }) => {
  return <ContainerWrap>{children}</ContainerWrap>;
};

const ContainerWrap = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default Container;

export const ContainerPadding = ({ children, width }) => {
  return <ContainerPaddingWrap width={width}>{children}</ContainerPaddingWrap>;
};
const ContainerPaddingWrap = styled.div`
  width: ${({ width }) => width};
  padding: 0 5rem;
  margin: 0 auto;

  @media (max-width: 670px) {
    width: 98%;
    padding: 0 1rem;
  }
`;
