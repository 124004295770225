import React from 'react';
import Container, { ContainerPadding } from '../Container';

const AboutUs = ({ id }) => {
  return (
    <Container>
      <ContainerPadding width='95%'>
        <div className='pt-30' id={id}>
          <div className='section-title'>
            <h2>من نحن</h2>
            <p className='about_paragraph'>
              شركة سعودية نسعى فيها بكل جدٍ وإبداع على أن نكون جزء من الحل لكثير
              من القطاعات المختلفة؛ وذلك بتقديم خدمات احترافية متكاملة في مجال
              العمالة والخدمات المساندة.
            </p>
          </div>
        </div>
      </ContainerPadding>
    </Container>
  );
};

export default AboutUs;
