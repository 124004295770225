import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './style.css';

const Banner = () => {
  return (
    <section id='banner' className='mt-70'>
      <div className='banner-parallax' data-banner-height='800'>
        <div className='slide-content'>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1}
            navigation={true}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            className='mySwiper'>
            <SwiperSlide>
              <img src='/assets/images/slider5.jpg' alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <img src='/assets/images/designWebsites.jpg' alt='' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Banner;
